import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				DriveAway Rentals
			</title>
			<meta name={"description"} content={"Досліджуйте нові дороги з легкістю"} />
			<meta property={"og:title"} content={"DriveAway Rentals"} />
			<meta property={"og:description"} content={"Досліджуйте нові дороги з легкістю"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenuOverride7" />
			<Override slot="quarklycommunityKitMenuOverride5" />
			<Override slot="quarklycommunityKitMenuOverride6" />
			<Override slot="text" />
			<Override slot="icon" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
			<Override slot="icon1" />
		</Components.Header>
		<Components.Hero>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text6" />
			<Override slot="link" />
			<Override slot="icon" />
			<Override slot="icon1" />
			<Override slot="icon2" />
			<Override slot="icon3" />
			<Override slot="icon4" />
			<Override slot="linkBox4" />
		</Components.Hero>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-8">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					width="100%"
				>
					<Image
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-3%20%281%29.jpg?v=2024-06-03T09:49:58.062Z"
						object-fit="cover"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/665d91718d4a0c00202fe404/images/2-3%20%281%29.jpg?v=2024-06-03T09%3A49%3A58.062Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665d91718d4a0c00202fe404/images/2-3%20%281%29.jpg?v=2024-06-03T09%3A49%3A58.062Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665d91718d4a0c00202fe404/images/2-3%20%281%29.jpg?v=2024-06-03T09%3A49%3A58.062Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665d91718d4a0c00202fe404/images/2-3%20%281%29.jpg?v=2024-06-03T09%3A49%3A58.062Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665d91718d4a0c00202fe404/images/2-3%20%281%29.jpg?v=2024-06-03T09%3A49%3A58.062Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665d91718d4a0c00202fe404/images/2-3%20%281%29.jpg?v=2024-06-03T09%3A49%3A58.062Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665d91718d4a0c00202fe404/images/2-3%20%281%29.jpg?v=2024-06-03T09%3A49%3A58.062Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-order="1"
				justify-content="space-between"
				align-items="flex-start"
				padding="24px 24px 24px 24px"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						У DriveAway Rentals ми пишаємося тим, що пропонуємо різноманітний парк автомобілів найвищого рівня, щоб задовольнити різноманітні потреби подорожей
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--darkL1" font="--lead" lg-text-align="center">
					{" "}Від динамічних компактних автомобілів, ідеальних для міської їзди, до просторих позашляховиків, ідеальних для сімейних прогулянок, наші транспортні засоби ретельно обслуговуються, щоб забезпечити максимальну продуктивність і надійність. Ми дотримуємося своєї обіцянки щодо надання виняткового рівня обслуговування клієнтів, простого процесу бронювання та прозорого ціноутворення без будь-яких прихованих платежів.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Про нас
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--lead"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Якщо ви готуєтеся до мальовничої подорожі, потрібен надійний транспортний засіб для ділових поїздок або бажаєте отримати стильну поїздку на особливу подію, ми допоможемо вам. Наше зобов’язання полягає в тому, щоб надати вам бездоганний досвід оренди, забезпечуючи комфорт і легкість у дорозі.
				</Text>
				<Link
					href="/services"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Послуги
				</Link>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="40%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						1
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Вибір преміум-класу
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Вибирайте з нашого широкого асортименту добре обслуговуваних автомобілів найновішої моделі, які точно відповідають вашим потребам, будь то економія палива, простір або розкіш.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						2
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Доступні ціни
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Насолоджуйтесь конкурентоспроможними цінами з чіткими умовами та без сюрпризів під час оформлення замовлення.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						3
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Адаптивні плани оренди
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Незалежно від того, чи потрібна вам машина на кілька годин, тиждень чи місяць, ми пропонуємо гнучкі плани відповідно до вашого графіка.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});